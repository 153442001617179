import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  useQuery,
  QueryHookOptions,
  OperationVariables,
  QueryResult,
  from,
} from '@apollo/client'
import { DocumentNode } from 'graphql'
import { setContext } from '@apollo/client/link/context'
import axios, { AxiosError } from 'axios'
import fetch from 'isomorphic-unfetch'
import merge from 'lodash/merge'
import isEqual from 'lodash/isEqual'
import { useCallback, useMemo } from 'react'
import { PREVIEW_AUTH_HEADER_NAME, SITE_KEY_HEADER_NAME } from '../constants'
import jwt_decode from 'jwt-decode'
import { LooseObject } from '@interflora/ui-components/build/common/props'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/nextjs'

const JWT_TOKEN = 'JWT-TOKEN'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let ssrAuthToken = null
let isVisualisation = false

// Feature flags that will be sent on all graphQL requests
const headerFeatureFlags = ['alternate-purchase-flow']

const filterFeatureFlags = (featureFlags = {}) =>
  Object.entries(featureFlags)
    .filter(([key]) => headerFeatureFlags.includes(key))
    .reduce((result, [key, value]) => ({ ...result, [key]: value }), {})

const featureFlagLink = (featureFlags: any) =>
  setContext(async (_, { headers }) => {
    const flags = filterFeatureFlags(featureFlags)
    const featureFlagHeaders = Object.keys(flags).length ? { 'X-Feature-Flags': JSON.stringify(flags) } : {}
    return { headers: { ...headers, ...featureFlagHeaders } }
  })

const experimentLink = setContext(async (_, { activeExperiments = {}, headers }) => {
  const experimentHeaders = Object.keys(activeExperiments).length
    ? { 'X-Experiments': JSON.stringify(activeExperiments) }
    : {}
  return { headers: { ...headers, ...experimentHeaders } }
})
const errorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      if (message.includes('401')) {
        if (localStorage.getItem(JWT_TOKEN)) {
          localStorage.removeItem(JWT_TOKEN)
          window.location.href = '/sign-in'
        }
      } else {
        console.log(message)
        Sentry.captureException(new Error(message))
      }
    })
  }

  if (networkError) {
    console.log(networkError)
    Sentry.captureException(networkError)
  }

  // Forward the operation to retry if it's not a 401 error
  if (!graphQLErrors || !graphQLErrors.some(({ message }) => message.includes('401'))) {
    try {
      return forward(operation)
    } catch (e) {
      console.log(e)
      Sentry.captureException(e)
    }
  }
})

const authLink = setContext(async (_, { headers }) => {
  if (!ssrAuthToken && typeof window !== 'undefined') {
    ssrAuthToken = localStorage.getItem(JWT_TOKEN)
  }

  if (ssrAuthToken) {
    try {
      const decodedToken: any = jwt_decode(ssrAuthToken)
      if (!decodedToken?.exp) {
        ssrAuthToken = ''
        console.warn('No `exp` property on token. Decoded token:', decodedToken)
        throw new Error('No `exp` property on token')
      }
      if (new Date(decodedToken.exp * 1000).getTime() <= Date.now()) {
        ssrAuthToken = ''
        console.warn('Token has expired. Decoded token:', decodedToken)
        throw new Error('Token has expired')
      }
    } catch (e) {
      try {
        const response = await axios.post(
          `${
            typeof window === 'undefined'
              ? process.env.MIDDLEWARE_AUTH_ENDPOINT
              : process.env.NEXT_PUBLIC_MIDDLEWARE_AUTH_ENDPOINT
          }/refresh`,
          {},
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
              [SITE_KEY_HEADER_NAME]: getSiteKey(),
            },
          }
        )
        ssrAuthToken = response.data.authToken
        if (typeof window !== 'undefined') {
          localStorage.setItem(JWT_TOKEN, ssrAuthToken)
        }
      } catch (e) {
        console.log('Failed to refresh jwt token')
        ssrAuthToken = null
      }
    }
  }
  if (!ssrAuthToken) {
    const response = await axios.post(
      `${
        typeof window === 'undefined'
          ? process.env.MIDDLEWARE_AUTH_ENDPOINT
          : process.env.NEXT_PUBLIC_MIDDLEWARE_AUTH_ENDPOINT
      }/login/anonymous`,
      {},
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          [SITE_KEY_HEADER_NAME]: getSiteKey(),
        },
      }
    )
    ssrAuthToken = response.data.authToken
    if (typeof window !== 'undefined') {
      localStorage.setItem(JWT_TOKEN, ssrAuthToken)
    }
  }
  headers.authorization = `Bearer ${ssrAuthToken}`

  if (isVisualisation) {
    headers[PREVIEW_AUTH_HEADER_NAME] =
      typeof window === 'undefined' ? process.env.PREVIEW_AUTH_TOKEN : process.env.NEXT_PUBLIC_PREVIEW_AUTH_TOKEN
  }

  headers[SITE_KEY_HEADER_NAME] = getSiteKey()

  return { headers }
})

const httpLink = new HttpLink({
  uri:
    typeof window === 'undefined'
      ? process.env.MIDDLEWARE_GRAPHQL_ENDPOINT
      : process.env.NEXT_PUBLIC_MIDDLEWARE_GRAPHQL_ENDPOINT,
  credentials: 'include',
  fetch,
})

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined

function createApolloClient(featureFlags: any) {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([featureFlagLink(featureFlags), experimentLink, errorLink, authLink.concat(httpLink)]),
    cache: new InMemoryCache({}),
  })
}

export function initializeApollo(initialState = null, useVisualisation = false, featureFlags = []) {
  isVisualisation = useVisualisation
  const _apolloClient = apolloClient ?? createApolloClient(featureFlags)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray: Array<Record<string, unknown>>, sourceArray: Array<Record<string, unknown>>) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function addApolloState(client: ApolloClient<NormalizedCacheObject>, pageProps: any) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

export function useApollo(pageProps: any) {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const { featureFlags } = pageProps
  const store = useMemo(() => initializeApollo(state, false, featureFlags), [state, featureFlags])
  return store
}

export function signIn(data: LooseObject) {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    [SITE_KEY_HEADER_NAME]: getSiteKey(),
  }
  if (ssrAuthToken) {
    headers.Authorization = `Bearer ${ssrAuthToken}`
  }
  return axios
    .post(`${process.env.NEXT_PUBLIC_MIDDLEWARE_AUTH_ENDPOINT}/login/customer`, data, {
      withCredentials: true,
      headers,
    })
    .then((response) => {
      localStorage.setItem(JWT_TOKEN, response.data.authToken)
      ssrAuthToken = response.data.authToken
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          throw new Error(
            'We were unable to find an account that matched the details provided. Please check your email address and password.'
          )
        }
      }
      throw new Error('There was a problem signing in. Please try again.')
    })
}

export function updatePassword(data: LooseObject) {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    [SITE_KEY_HEADER_NAME]: getSiteKey(),
  }
  if (ssrAuthToken) {
    headers.Authorization = `Bearer ${ssrAuthToken}`
  }
  return axios
    .post(`${process.env.NEXT_PUBLIC_MIDDLEWARE_AUTH_ENDPOINT}/login/customer/password`, data, {
      withCredentials: true,
      headers,
    })
    .then((response) => {
      localStorage.setItem(JWT_TOKEN, response.data.authToken)
      ssrAuthToken = response.data.authToken
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          throw new Error('We were unable to change your password. Please check your current password.')
        }
      }
      throw new Error('There was a problem signing in. Please try again.')
    })
}

export function register(data: LooseObject) {
  return axios
    .post(`${process.env.NEXT_PUBLIC_MIDDLEWARE_AUTH_ENDPOINT}/register`, data, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        [SITE_KEY_HEADER_NAME]: getSiteKey(),
      },
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 400) {
          throw new Error('We were unable to register your account. Please sign in if you already have an account.')
        }
      }
      throw new Error('There was a problem registering. Please try again.')
    })
}

/**
 * Small wrapper around `useQuery` so that we can use it imperatively.
 *
 * @see Credit: https://github.com/apollographql/react-apollo/issues/3499#issuecomment-586039082
 *
 * @example
 * const callQuery = useImperativeQuery(query, options)
 * const handleClick = async () => {
 *   const{ data, error } = await callQuery()
 * }
 */
export function useImperativeQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options: QueryHookOptions<TData, TVariables> = {}
): QueryResult<TData, TVariables>['refetch'] {
  const { refetch } = useQuery<TData, TVariables>(query, {
    ...options,
    fetchPolicy: 'no-cache',
    skip: true,
  })

  const imperativelyCallQuery = useCallback(
    (queryVariables: TVariables) => {
      return refetch(queryVariables)
    },
    [refetch]
  )

  return imperativelyCallQuery
}

/**
 * Get the site key based on the appropriate environment variable.
 */
export function getSiteKey() {
  return typeof window === 'undefined' ? process.env.SITE_KEY : process.env.NEXT_PUBLIC_SITE_KEY
}

/**
 * Confirmation registration
 */
export function confirmationRegistration(data: LooseObject) {
  return axios
    .post(`${process.env.NEXT_PUBLIC_MIDDLEWARE_AUTH_ENDPOINT}/order-confirmation/register`, data, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${ssrAuthToken}`,
        'Content-Type': 'application/json',
        [SITE_KEY_HEADER_NAME]: getSiteKey(),
      },
    })
    .then((response) => {
      localStorage.setItem(JWT_TOKEN, response.data.authToken)
      ssrAuthToken = response.data.authToken
    })
    .catch(() => {
      throw new Error('There was a problem registering. Please try again.')
    })
}

/**
 * Confirmation SignIn
 */
export function confirmationSignIn(data: LooseObject) {
  return axios
    .post(`${process.env.NEXT_PUBLIC_MIDDLEWARE_AUTH_ENDPOINT}/order-confirmation/signIn`, data, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${ssrAuthToken}`,
        'Content-Type': 'application/json',
        [SITE_KEY_HEADER_NAME]: getSiteKey(),
      },
    })
    .then((response) => {
      localStorage.setItem(JWT_TOKEN, response.data.authToken)
      ssrAuthToken = response.data.authToken
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          throw new Error(
            'We were unable to find an account that matched the details provided. Please check your email address and password.'
          )
        }
      }
      throw new Error('There was a problem signing in. Please try again.')
    })
}

/**
 * Sign out
 */
export function signOut() {
  return axios
    .post(`${process.env.NEXT_PUBLIC_MIDDLEWARE_AUTH_ENDPOINT}/logout`, undefined, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${ssrAuthToken}`,
        'Content-Type': 'application/json',
        [SITE_KEY_HEADER_NAME]: getSiteKey(),
      },
    })
    .catch((error) => {
      console.log(error)
    })
    .finally(() => {
      localStorage.removeItem(JWT_TOKEN)
      ssrAuthToken = null
    })
}
